<template>
    <!-- 전체 콘텐츠 영역 -->
      <div class="main-contents">
        <div class="news_wrap">
          <div class="path"><a href="javascript:void(0);" @click="$router.go(-1)">◀ 목록가기</a></div>

          <!-- view -->
          <div class="news-view">
            <div class="view-title">[ 하이프로 뉴스 ] {{newsInfo.newsCenterTitle}}</div>
            <div class="attatched-image ta-left" v-if="newsInfo.attaFileSeq">
              <!-- <img :src="'/api/main/bbs/noticeImage?attaFileSeq='+noticeInfo.attaFileSeq" :alt="noticeInfo.attaFileNm" /> -->
              <ImageComp :src="'/api/main/bbs/newsCenterImage?attaFileSeq='+newsInfo.attaFileSeq" alt="공지사항 이미지"/>
            </div>
            <div class="view-text">
              <p>
                {{newsInfo.newsCenterCont}}
              </p>
            </div>
          </div>

        </div>
      </div>
</template>

<script>

export default {
    data () {
        return {
            newsCenterSeq : this.$route.params.newsCenterSeq || '',
            newsInfo : {
                attaFileSeq : '',
                attaFileNm : '',
                newsCenterTitle : '',
                newsCenterCont : '',
            },
        }
    },

    beforeMount() {
        this.getNewsDetail();
    },
    methods: {
        getNewsDetail() {
            this.$.httpPost('/api/main/bbs/getNewsDetail', {newsCenterSeq : this.newsCenterSeq})
                .then(res => {
                    //console.log(res);
                    this.newsInfo = res.data.info;
                }).catch(this.$.httpErrorCommon);
        },    
    },
    
}
</script>